/* Theme colors */

$primary-color: #4ab623;
$error-color: #eb5757;
$error-color-dark: #bc4545;
$error-color-darker: #8d3434;
$error-color-light: #fddede;
$warning-color: #ffc107;
$warning-color-dark: #c79100;
$secondary-color: #8acc6f;
$light-color: #bee2af;
$extra-light-color: #def0d7;
$light-grey: #fafafa;
$light-medium-grey: #d8d8d8;
$contrast-color: #41454a;
$contrast-light-color: #51555c;
$contrast-dark-color: #231f20;
$accent-color: #ffff;
$main-background-color: #ffff;
$alt-background-color: #f4f3fa;
/* $green-background must be used with background property and not background color prpoerty to work*/
$green-background: linear-gradient(180deg, rgba(74, 182, 36, 0.15) 0%, rgba(74, 182, 36, 0.09) 64.89%);
$text-color: #000;
$grey: #a0a2a4;
$sweet-blue: #18577a;

/* Responsive breakpoints */

$mobile-breakpoint: 640px;
$small-desktop-breakpoint: 1280px;
$xs-mobile-breakpoint: 568px;
$xxs-mobile-breakpoint: 350px;
$large-tablet-breakpoint: 1024px;
$standard-tablet-breakpoint: 820px;
$wide-screen-breakpoint: 1800px;

$max-content-width: 2000px;
$half-content-width: calc($max-content-width / 2);

/* partner's colors */
$haven-life-blue: #1bb2dd;
$wn-blue: #284589;
$pgl-blue: #00779f;
$fg-purple: #582c83;
$nationwide-blue: #1658a5;
$colpenn-blue: #00538c;
$legal-and-general-grey: #f9f7f7ed;
$assurity-red: #a72b2a;
